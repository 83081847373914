import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { BasicModalService, FetchMenuService, LayoutDataModel, ModalConfig, OutputEvent, SideNavConfig, SideNavConfigItem } from '@cws-ui/ngx-cws-app-layout';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, catchError, of, switchMap } from 'rxjs';
import { common_urls, common_urls_gasa } from 'src/environments/environment';
import { GasaFundEnhancementService } from './gasa-fund-enhancement/services/gasa-fund-enhancement.service';
import { TRACK_LINK_LABELS, TRACK_LINK_TYPE, TRACK_POPUP_TITLE, procedureRequestStatus } from './gasa-fund-enhancement/shared/constants';
import { ErrorModalComponent } from './gasa-fund-enhancement/shared/error-modal/error-modal.component';
import { LoaderService } from './service/loader.service';
import { CwsUrlNavigator } from './shared/cws-url-navigator';
import { ErrorFaqModalMsgs, ProgressModalMsgs } from './shared/side-menu-popup/enums/error-faq-modal-msgs.enum';
import { CancellationErrorCodes, LoansFundWithdrawalsErrorCodes, ProcedurePremiumInsuranceErrorCodes, ReissueErrorCodes } from './shared/side-menu-popup/enums/side-menu-error-codes.enum';
import { Cancellation, ChangeContractInformation, SideMenuItems } from './shared/side-menu-popup/enums/side-menu-items.enums';
import { SFDCDirectNavigationEventEnvBasedUrlMapping, SFDCDirectNavigationEventUrlMapping, SFDCNavigationEventUrlMapping } from './shared/side-menu-popup/side-menu-constants';
import { SideMenuPopupService } from './shared/side-menu-popup/side-menu-popup.service';
import { EtaxLayoutData } from './shared/side-nav-config/etax-config';
import { FraLayoutData, fraScreenType } from './shared/side-nav-config/fra-config';
import { GasaLayoutData, GasaScreenType } from './shared/side-nav-config/gasa-config';
import { create_UUID } from './utils/commons';
import { TrackLinkParams } from './gasa-fund-enhancement/shared/models/gasa-adobe-analytics-model';
import { GasaAdobeAnalytics } from './gasa-fund-enhancement/shared/gasa-adobe-analytics';
import { C360LayoutData } from './shared/side-nav-config/c360-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('error1Template') error1Template!: TemplateRef<any>;
  @ViewChild('error2Template') error2Template!: TemplateRef<any>;
  @ViewChild('error3Template') error3Template!: TemplateRef<any>;
  statusCode!: string;

  isCWSPage: boolean = false
  isAuth: boolean = false;
  sideNavAllowedRoutes: string[] = [
    '/ereport/index',
    '/ereport/basic-information',
    '/ereport/accumulation-status',
    '/ereport/exchange-rate',
    '/ereport/recipient',
    '/gasa-fund/policy-details',
    '/gasa-fund/fund-switching/disclaimer',
    '/gasa-fund/fund-switching/switching',
    '/gasa-fund/fund-switching/confirmation',
  ]

  errorFaqModalMsgText: string = "";
  progressModalText: string = "";
  id: string = "";
  popupModalSubscribe!: Subscription;
  @ViewChild("showFamilyNameMsg") showFamilyNameMsg!: TemplateRef<any>;
  @ViewChild("addressChange") addressChange!: TemplateRef<any>;
  @ViewChild("errorFaqModal") errorFaqModal!: TemplateRef<any>;
  @ViewChild("progressModal") progressModal!: TemplateRef<any>;
  @ViewChild("taxCertificate") taxCertificate!: TemplateRef<any>;
  @ViewChild("cancelContractChange") cancelContractChange!: TemplateRef<any>;

  // dynamic config for side nav
  layoutData!: LayoutDataModel;
  screenType!: string;
  isMortgage = false;
  @ViewChild("creditCardChange") creditCardChange!: TemplateRef<any>;
  constructor(private translateService: TranslateService,
    private modalService: BasicModalService,
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private fetchMenuService: FetchMenuService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sideMenuPopupService: SideMenuPopupService,
    private dialog: MatDialog,
    private gasaService: GasaFundEnhancementService,
    public matDialog: MatDialog,
    private loaderService: LoaderService) {
    this.translateService.setDefaultLang('ja');
    this.translateService.use('ja');
  }

  ngOnInit() {
    let policyNumber: string, smallProdCD: string;
    this.route.queryParamMap.subscribe((res: any) => {
      let params = res['params'];
      CwsUrlNavigator.policyNumber = policyNumber = params['polNo'] ? encodeURIComponent(params['polNo']) : this.gasaService.policyNumber;
      if (params['sprodCd']) {
        smallProdCD = encodeURIComponent(params['sprodCd']);
      }
      else if (params['sprodCode']) {
        smallProdCD = encodeURIComponent(params['sprodCode']);
      }
      else {
        smallProdCD = this.gasaService.smallProdCD || '';
      }
      CwsUrlNavigator.sProdCd = smallProdCD;
      CwsUrlNavigator.id = this.id = params["polId"] ? params['polId'] : this.gasaService.id;
    });

    this.router.events.subscribe((evt: any) => {
      if ((evt instanceof NavigationEnd)) {
        if (evt.url.includes('/cws-registration/landing-page') || evt.url.includes("/stop-mailing-certificates")) {
          this.isCWSPage = true;
          if (evt.url.includes("/stop-mailing-certificates"))
            this.isMortgage = true;
          else
            this.isMortgage = false;
        }
        else {
          this.isCWSPage = false; // gasa, fra, c360, etax

          this.setSideMenuConfig();
          const isAllowed = this.sideNavAllowedRoutes.some(sideNaveRoute => {
            const urlItems = evt.url.split("?")
            return urlItems[0] === sideNaveRoute && this.isAuth
          });
          if (isAllowed)
            this.fetchSideMenu(policyNumber, smallProdCD); // gasa, fra
          else
            this.layoutData.showSideNav = false; // c360
        }
      }
      if (evt instanceof GuardsCheckEnd) {
        this.isAuth = evt.shouldActivate
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.gasaService.accumulatedFundItemLeftMenu.subscribe(response => {
      if (response) {
        this.statusCode = Object(response.metadata)?.fundSwitchingErrorCode?.toString();
      }
    })
  }
  /**
   * set side menu confiugration dynamically as per projects requirements
   */
  setSideMenuConfig() {
    if (this.router.url.includes("gasa-fund/")) {
      this.layoutData = GasaLayoutData;
      this.screenType = GasaScreenType;
    }
    else if (this.router.url.includes("ereport/")) {
      this.layoutData = FraLayoutData;
      this.screenType = fraScreenType;
    }
    else if (this.router.url.includes("/etax/")) {
      this.layoutData = EtaxLayoutData;
    }
    else if (this.router.url.includes("/family-registration/")) {
      this.layoutData = C360LayoutData;
    }
  }

  /**
    /**
     * Following method is related to gasa fund switching and should not be removed as It would impact the gasa fund switching functionality.
     * This method checks if navigation back to SFDC is required on the clicked event. If yes, It navigates to the corresponding URL.
     * @param event : when we click on one of the menu items in the side navigation bar, the event is represented by event parameter here.
     */
  handleMenuClick(event: OutputEvent) {
    // redirect if need to redirect to SFDC
    if (Object.keys(SFDCDirectNavigationEventUrlMapping).includes(event.target)) {
      const navigationUrl = SFDCDirectNavigationEventUrlMapping[event.target];
      window.open(navigationUrl, "_blank");
    }
    else if (Object.keys(SFDCDirectNavigationEventEnvBasedUrlMapping).includes(event.target)) {
      const baseUrl = common_urls_gasa.cwsBaseUrl;
      const subPath = SFDCDirectNavigationEventEnvBasedUrlMapping[event.target];
      if (event.target == "inquiryItem") {
        window.open(`${baseUrl}/${subPath}${this.id}`, "_self");
      }
      else {
        window.open(`${baseUrl}/${subPath}`, "_self");
      }
    }
    else if (event.target == "accumulatedFundTransferSwitchingItem") {
      this.redirectToFundSwitching();
    }
    else if (event.target == "rakuRakuFamilyRegistrationSubItem") {
      const polNo = CwsUrlNavigator.policyNumber || '';
      const sprodCd = CwsUrlNavigator.sProdCd || '';
      const lprodCd = CwsUrlNavigator.lProdCd || '';
      const id = CwsUrlNavigator.id || '';
      const queryParam = { "sprodCd": sprodCd, "lprodCd": lprodCd, "polNo": polNo, "id": id };
      this.redirectToURL("family-registration/home", true, queryParam);
    }
    else {
      const subMenu = this.fetchMenuService.findSideNavItem(this.layoutData.leftMenuItems.leftMenuItems, event.target);
      if (subMenu)
        // event.parent is referencing parent of clicked submenu.
        switch (event.parent) {
          case SideMenuItems.ChangeContractInformation:
            this.showContractInformation(event.target, subMenu);
            break;
          case SideMenuItems.Cancellation:
            this.showCancellation(event.target, subMenu);
            break;
          case SideMenuItems.ReissueProcedure:
            this.showReIssueProcedure(event.target, subMenu);
            break;
          case SideMenuItems.ProceduresInsurancePremiumPayment:
            this.showProcedurePremiumInsurancePayment(event.target, subMenu);
            break;
          case SideMenuItems.LoansFundWithdrawals:
            this.showLoansFundWithdrawals(event.target, subMenu);
            break;
        }
    }
  }

  /**
  * Following method is related to gasa fund switching and should not be removed as It would impact the gasa fund switching functionality.
  * The puspose of this method is to construct the navigation url which would be used to navigate back to SFDC site.
  * @param eventName : when we click on one of the menu items in the side navigation bar, the event is represented by event parameter here.
  */
  getNevigationUrl(eventName: string) {
    const baseUrl = common_urls.cwsBaseUrl;
    const subPath = SFDCNavigationEventUrlMapping[eventName];
    const polNo = CwsUrlNavigator.policyNumber;//this.gasaService.policyNumber;
    const sprodCd = CwsUrlNavigator.sProdCd;//this.gasaService.smallProdCD;
    const lprodCd = CwsUrlNavigator.lProdCd;//this.gasaService.largeProdCD;
    const id = CwsUrlNavigator.id;//this.gasaService.id;
    return `${baseUrl}/${subPath}?from=policyinquiry&source=gasafundswitching&cws=1&sprodCd=${sprodCd}&lprodCd=${lprodCd}&polNo=${polNo}&id=${id}`;
  }

  /**
   * check whether error code comes among the values mentioned and accordingly opens corresponding error dialog.
   * If not, redirects to fund switching page.
   */
  redirectToFundSwitching() {
    // show apprpriate error messages in the dialog as per the status code received from api
    if (this.statusCode && Object.values(procedureRequestStatus).map(String).includes(this.statusCode)) {
      switch (this.statusCode) {

        // status: We cannot accept requests made within 2 business days of the date the company received your previous switching request.
        case procedureRequestStatus.CWS001.toString():
          this.openErrorDialog(this.error1Template);
          GasaAdobeAnalytics.trackPopup(TRACK_POPUP_TITLE.errorCWS001);
          break;

        // status: As we have already accepted cancellation procedures, we cannot accept switching procedures.
        case procedureRequestStatus.CWS002.toString():
          this.openErrorDialog(this.error2Template);
          GasaAdobeAnalytics.trackPopup(TRACK_POPUP_TITLE.errorCWS002);
          break;

        // status: We cannot accept switching procedures as there are similar procedures that have recently been completed.
        case procedureRequestStatus.CWS003.toString():
          this.openErrorDialog(this.error3Template);
          GasaAdobeAnalytics.trackPopup(TRACK_POPUP_TITLE.errorCWS003);
          break;
      }

    }
    else {
      let eventInfo: TrackLinkParams = {
        label: TRACK_LINK_LABELS.fundSwitchLeftMenu
      }
      GasaAdobeAnalytics.trackLinkClick(TRACK_LINK_TYPE.internal, eventInfo);
      // if all conditions satisfies, redirect to fund-switching page
      this.router.navigate(['gasa-fund/fund-switching/disclaimer'], { relativeTo: this.route });
    }
  }

  /**
   * Opens error dialog for specific error codes
   * @param bodyTemplate error template  which needs to be shown in the error dialog
   */
  openErrorDialog(bodyTemplate: TemplateRef<any>) {
    this.matDialog.open(ErrorModalComponent, {
      data: {
        customBody: bodyTemplate,
      },
      autoFocus: false, // changes for GFE-1633
      restoreFocus: false,  // changes for GFE-1633
      disableClose: true,  // changes for GFE-1632
    }).afterClosed().subscribe((response) => {
    })
  }

  /**
 * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of contract information changes and should not be removed.
 * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
 * @param subMenu : This param hold all the information and properties for the clicked submenu item.
 */
  showContractInformation(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl] = this.sideMenuPopupService.changeContractInformation(sideMenuItem, subMenu, this.errorFaqModal, this.showFamilyNameMsg, this.addressChange)
    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl)
    }
    else {
      if (sideMenuItem == ChangeContractInformation.ChangeNameBeneficiaryDesignatedProxyClaimantContractorInvoiceOrder && subMenu.metadata) {
        this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["displayRightHolderMsg"] as keyof typeof ErrorFaqModalMsgs];
      }
      this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
        if (result == "true") {
          if (redirectUrl) {
            this.redirectToURL(redirectUrl)
          }
        }
        // else , popup will be closed with no action
      });
    }
  }

  /**
  * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of Cancellation and should not be removed.
  * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
  *  @param subMenu : This param hold all the information and properties for the clicked submenu item.
  */
  showCancellation(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl] = this.sideMenuPopupService.cancellation(sideMenuItem, subMenu, this.errorFaqModal, this.progressModal, this.cancelContractChange);
    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl)
    }
    else {
      if (subMenu.metadata) {
        switch (subMenu.metadata["popupActionCode"]) {
          case CancellationErrorCodes.CWSTDSB001:
          case CancellationErrorCodes.CWSRWS001:
          case CancellationErrorCodes.CWSRWS002:
            this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ErrorFaqModalMsgs];
            break;
          case CancellationErrorCodes.CWSTDSB003:
          case CancellationErrorCodes.CWSRWS004:
            this.progressModalText = ProgressModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ProgressModalMsgs];
            break;
          default:
            break;
        }

        this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
          if (result == "true") {
            if (redirectUrl) {
              this.redirectToURL(redirectUrl)
            }
          }
          else if (result == "cancelContractChange") {
            // if cancel contract is returned then we need to again manipulate as per business Logic
            this.showCancellation(Cancellation.CancellationInvoiceRequest, subMenu);
          }
          // else , popup will be closed with no action
        });
      }
    }

  }

  /**
  * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of ReIssue Procedure and should not be removed.
  * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
  * @param subMenu : This param hold all the information and properties for the clicked submenu item.
  */
  showReIssueProcedure(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl, isInternalNavigation, queryParam] = this.sideMenuPopupService.reissueProcedure(sideMenuItem, subMenu, this.errorFaqModal, this.progressModal, this.taxCertificate);

    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl, isInternalNavigation, queryParam);
    }
    else {
      if (subMenu.metadata) {
        if (subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI001 || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI002) {
          this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ErrorFaqModalMsgs];;
        }
        if (subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI003 || subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI004) {
          this.progressModalText = subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI003 ? "" : "app.common-modal.cancellation.text-3";
        }
        this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
          if (result == "true") {
            if (redirectUrl) {
              this.redirectToURL(redirectUrl);
            }
          }
          // else , popup will be closed with no action
        });
      }
    }

  }

  /**
    * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of ReIssue Procedure and should not be removed.
    * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
    * @param subMenu : This param hold all the information and properties for the clicked submenu item.
    */
  showProcedurePremiumInsurancePayment(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl] = this.sideMenuPopupService.procedurePremiumInsurancePayment(sideMenuItem, subMenu, this.errorFaqModal, this.progressModal, this.creditCardChange);

    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl);
    }
    else {
      if (subMenu.metadata) {
        switch (subMenu.metadata["popupActionCode"]) {
          case ProcedurePremiumInsuranceErrorCodes.CWSBAC001:
          case ProcedurePremiumInsuranceErrorCodes.CWSBAC002:
          case ProcedurePremiumInsuranceErrorCodes.CWSCCC001:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB001:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB002:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB003:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB004:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB005:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB006:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB007:
            this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ErrorFaqModalMsgs];
            break;
          case ProcedurePremiumInsuranceErrorCodes.CWSCCC003:
          case ProcedurePremiumInsuranceErrorCodes.CWSURB009:
            this.progressModalText = ProgressModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ProgressModalMsgs];
            break;
          default:
            break;
        }

        this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
          if (result == "true") {
            if (redirectUrl) {
              this.redirectToURL(redirectUrl);
            }
          }
          // else , popup will be closed with no action
        });
      }
    }

  }

  /**
    * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of Loans fund withdrawls and should not be removed.
    * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
    * @param subMenu : This param hold all the information and properties for the clicked submenu item.
    */
  showLoansFundWithdrawals(sideMenuItem: string, subMenu: SideNavConfigItem) {
    // As per the business logic, this method is returning in-case of popup : pop-up config with redirection url ,if not then redirection url.
    const [config, redirectUrl] = this.sideMenuPopupService.loansFundWithdrawals(sideMenuItem, subMenu, this.errorFaqModal, this.progressModal);
    if (!config) {
      if (redirectUrl)
        this.redirectToURL(redirectUrl);
    }
    else {
      if (subMenu.metadata) {
        switch (subMenu.metadata["popupActionCode"]) {
          case LoansFundWithdrawalsErrorCodes.CWSUPL001:
          case LoansFundWithdrawalsErrorCodes.CWSUPL005:
          case LoansFundWithdrawalsErrorCodes.CWSUPL006:
            this.errorFaqModalMsgText = ErrorFaqModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ErrorFaqModalMsgs];
            break;
          case LoansFundWithdrawalsErrorCodes.CWSUPL002:
          case LoansFundWithdrawalsErrorCodes.CWSUPL003:
            this.progressModalText = ProgressModalMsgs[subMenu.metadata["popupActionCode"] as keyof typeof ProgressModalMsgs];
            break;
          default:
            break;
        }
        this.popupModalSubscribe = this.showPopUp(config).subscribe(result => {
          if (result == "true") {
            if (redirectUrl) {
              this.redirectToURL(redirectUrl);
            }
          }
          // else , popup will be closed with no action
        });
      }
    }

  }

  showPopUp(popUpConfig: ModalConfig): Observable<any> {
    this.dialog.closeAll();
    const modalConfig: ModalConfig = {
      ...popUpConfig,
      customConfig: {
        autoFocus: true,
        restoreFocus: false
      }
    }
    return this.modalService.openModal(modalConfig);
  }

  private fetchSideMenu(policyNumber: string, smallProdCD: string) {
    let headers: HttpHeaders;
    const screenType = this.screenType;
    this.loaderService.show();
    //URL to be updated dynamically
    let url = `${common_urls.oodMenuItemsUrl}/menu-items/${create_UUID()}?policyNumber=${policyNumber}&smallProductCode=${smallProdCD}&screenType=${screenType}`;
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        headers = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken
        })
        return this.fetchMenuService.fetchMenu(url, this.http, headers);
      }),
      catchError((err) => {
        this.loaderService.hide();
        console.error(err);
        this.layoutData.showSideNav = false;
        return of()
      })
    ).subscribe(left_menu => {
      // this.fetchMenuService.replacePropInHref(left_menu, 'listOfContractsItem', ["homepage"], ["logout"]);
      // this.fetchMenuService.findSideNavItem(left_menu.leftMenuItems, 'listOfContractsItem');
      this.layoutData.showSideNav = true;
      this.layoutData.leftMenuItems = left_menu
      this.setAccumulatedFundSwitchingConfig(left_menu);
    });


  }
  setAccumulatedFundSwitchingConfig(leftMenu: SideNavConfig) {
    const data = leftMenu.leftMenuItems.filter(x => x.name == "accumulatedFundTransferSwitchingItem")[0];
    this.gasaService.accumulatedFundItemLeftMenu.next(data);
    this.loaderService.hide();
  }

  // this.layoutData.showSideNav = true;
  // this.layoutData.leftMenuItems = LEFT_MENU_ITEMS;

  ngOnDestroy() {
    if (this.popupModalSubscribe) this.popupModalSubscribe.unsubscribe();
  }

  redirectToURL(redirectURL: string, isInternalNavigation: Boolean | null = false, queryParam: { [key: string]: string } | null = null) {
    if (!isInternalNavigation)
      location.href = redirectURL;
    else
      if (queryParam)
        this.router.navigate([redirectURL], { queryParams: queryParam, relativeTo: this.route });
      else
        this.router.navigateByUrl(redirectURL);
  }
}